<template>
  <div>
    <q-card>
      <q-table
        class="sticky-action full-width"
        :title="title"
        :rows="items"
        :columns="columns"
        :filter="filter"
        row-key="id"
      >
        <template #top-left>
          <span class="text-h6 q-ml-sm">{{ title }}</span>
        </template>
        <template #top-right>
          <q-input dense debounce="300" color="primary" v-model="filter">
            <template v-slot:append>
              <q-icon name="search" />
            </template>
          </q-input>
        </template>
      </q-table>
    </q-card>
  </div>
</template>

<script>
import { computed, ref } from '@vue/runtime-core'
import { limitText } from '../utils/functions'
import { useStore } from 'vuex'

export default {
  props: {
    title: {
      type: String,
      default: ''
    },
    items: {
      type: Array,
      default: () => ([])
    }
  },
  setup (props) {
    const store = useStore()
    const profile = computed(() => store.state.profile)

    const filter = ref('')

    const columns = ref([
      { name: 'jobDescription', label: 'Job Description', align: 'left', field: 'jobDescription', sortable: true, format: val => limitText(val, 100, true) }
    ])

    const rows = computed(() => props.items)

    return {
      profile,
      filter,
      columns,
      rows
    }
  }
}
</script>
